import { map } from 'rxjs/operators';
import { ContentCollection } from './collection.types';
import { StrapiCollection } from './common.types';

export function emptyCollection(): StrapiCollection<any> {
  return {
    data: [],
    meta: {
      pagination: {
        total: 0,
      },
    },
  };
}

export function mapCollection<T, R>(transformFn: (entity: { id: string; attributes: T }) => R) {
  return map((collection: StrapiCollection<T>): ContentCollection<R> => {
    const items = collection.data.map((d) => transformFn(d));
    const totalItemsCount = collection.meta.pagination.total;
    return { items, totalItemsCount };
  });
}
