import { Injectable } from '@angular/core';
import { TranslatedError } from '@x/common/error';
import { ApolloErrorTranslator } from '@x/common/error/translators/apollo.error-translator';
import { GraphQLError } from 'graphql';
import {
  ContentErrorCodes,
  ContentErrorTranslation,
  CONTENT_ERROR_TRANSLATIONS,
} from './content-error-translations';

@Injectable()
export class ContentClientErrorTranslator extends ApolloErrorTranslator {
  translateGraphQLError(graphQlError: GraphQLError): TranslatedError {
    const code = (graphQlError.extensions?.code as string) ?? graphQlError.message;

    let translation: ContentErrorTranslation = {
      title: `GraphQL Error`,
      message: `Error occured during GraphQL execution.`,
    };

    if (code in CONTENT_ERROR_TRANSLATIONS) {
      translation = { ...CONTENT_ERROR_TRANSLATIONS[code as ContentErrorCodes] };
    }

    return {
      ...translation,
      code,
      detail: JSON.stringify(graphQlError, undefined, 2),
    };
  }
}
