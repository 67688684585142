import { mapDefiniteArray } from '@x/common/utils';
import { BlogIndexAttributesFragment } from '../gql/blog-index.gql.generated';
import { IBlogIndexContent } from './blog-index.types';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent } from './component.types';

export function mapBlogIndexContent(source: BlogIndexAttributesFragment): IBlogIndexContent {
  return {
    ...source,
    header: source.header
      ? (mapDefiniteArray(source.header)
          .map(mapDynamicComponent)
          .filter((c) => !!c) as AnyBrochureComponentContent[])
      : undefined,
    metaData: source.metaData ? mapMetaDataContent(source.metaData) : undefined,
  };
}
