import * as Types from '@x/schemas/content';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BrochureNavCategoryDetailAttributesFragment = { __typename: 'BrochureNavCategory', categoryName: string };

export type BrochureNavCategoryResponseFragment = { __typename: 'BrochureNavCategoryEntityResponse', data?: { __typename: 'BrochureNavCategoryEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavCategory', categoryName: string } | null | undefined } | null | undefined };

export type BrochureNavCategoryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BrochureNavCategoryQuery = { __typename: 'Query', brochureNavCategory?: { __typename: 'BrochureNavCategoryEntityResponse', data?: { __typename: 'BrochureNavCategoryEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavCategory', categoryName: string } | null | undefined } | null | undefined } | null | undefined };

export type BrochureNavItemsDetailAttributesFragment = { __typename: 'BrochureNavItem', text?: string | null | undefined, url?: string | null | undefined, openInNewTab?: boolean | null | undefined, linkPosition: Types.Enum_Brochurenavitem_Linkposition };

export type BrochureNavItemRelationResponseCollectionFragment = { __typename: 'BrochureNavItemRelationResponseCollection', data: Array<{ __typename: 'BrochureNavItemEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavItem', text?: string | null | undefined, url?: string | null | undefined, openInNewTab?: boolean | null | undefined, linkPosition: Types.Enum_Brochurenavitem_Linkposition } | null | undefined }> };

export type BrochureNavCategoriesDetailAttributesFragment = { __typename: 'BrochureNavCategory', categoryName: string, brochureNavItems?: { __typename: 'BrochureNavItemRelationResponseCollection', data: Array<{ __typename: 'BrochureNavItemEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavItem', text?: string | null | undefined, url?: string | null | undefined, openInNewTab?: boolean | null | undefined, linkPosition: Types.Enum_Brochurenavitem_Linkposition } | null | undefined }> } | null | undefined };

export type BrochureNavCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BrochureNavCategoriesQuery = { __typename: 'Query', brochureNavCategories?: { __typename: 'BrochureNavCategoryEntityResponseCollection', data: Array<{ __typename: 'BrochureNavCategoryEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavCategory', categoryName: string, brochureNavItems?: { __typename: 'BrochureNavItemRelationResponseCollection', data: Array<{ __typename: 'BrochureNavItemEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavItem', text?: string | null | undefined, url?: string | null | undefined, openInNewTab?: boolean | null | undefined, linkPosition: Types.Enum_Brochurenavitem_Linkposition } | null | undefined }> } | null | undefined } | null | undefined }> } | null | undefined };

export const BrochureNavCategoryDetailAttributesFragmentDoc = gql`
    fragment BrochureNavCategoryDetailAttributes on BrochureNavCategory {
  categoryName
}
    `;
export const BrochureNavCategoryResponseFragmentDoc = gql`
    fragment BrochureNavCategoryResponse on BrochureNavCategoryEntityResponse {
  data {
    id
    attributes {
      ...BrochureNavCategoryDetailAttributes
    }
  }
}
    ${BrochureNavCategoryDetailAttributesFragmentDoc}`;
export const BrochureNavItemsDetailAttributesFragmentDoc = gql`
    fragment BrochureNavItemsDetailAttributes on BrochureNavItem {
  text
  url
  openInNewTab
  linkPosition
}
    `;
export const BrochureNavItemRelationResponseCollectionFragmentDoc = gql`
    fragment BrochureNavItemRelationResponseCollection on BrochureNavItemRelationResponseCollection {
  data {
    id
    attributes {
      ...BrochureNavItemsDetailAttributes
    }
  }
}
    ${BrochureNavItemsDetailAttributesFragmentDoc}`;
export const BrochureNavCategoriesDetailAttributesFragmentDoc = gql`
    fragment BrochureNavCategoriesDetailAttributes on BrochureNavCategory {
  brochureNavItems {
    ...BrochureNavItemRelationResponseCollection
  }
  categoryName
}
    ${BrochureNavItemRelationResponseCollectionFragmentDoc}`;
export const BrochureNavCategoryDocument = gql`
    query BrochureNavCategory($id: ID!) {
  brochureNavCategory(id: $id) {
    data {
      id
      attributes {
        ...BrochureNavCategoryDetailAttributes
      }
    }
  }
}
    ${BrochureNavCategoryDetailAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrochureNavCategoryGQL extends Apollo.Query<BrochureNavCategoryQuery, BrochureNavCategoryQueryVariables> {
    document = BrochureNavCategoryDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BrochureNavCategoriesDocument = gql`
    query BrochureNavCategories {
  brochureNavCategories {
    data {
      id
      attributes {
        ...BrochureNavCategoriesDetailAttributes
      }
    }
  }
}
    ${BrochureNavCategoriesDetailAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrochureNavCategoriesGQL extends Apollo.Query<BrochureNavCategoriesQuery, BrochureNavCategoriesQueryVariables> {
    document = BrochureNavCategoriesDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }