import { mapDefiniteArray } from '@x/common/utils';
import { mapShopNavigationItemContent } from '@x/content/client';
import { ErrorAttributesFragment } from '../gql/common.gql.generated';
import {
  ComponentBrochureAccordionAttributesFragment,
  ComponentBrochureActionBannerAttributesFragment,
  ComponentBrochureActionBannerItemAttributesFragment,
  ComponentBrochureActionGridAttributesFragment,
  ComponentBrochureActionGridItemAttributesFragment,
  ComponentBrochureActionListAttributesFragment,
  ComponentBrochureActionListItemAttributesFragment,
  ComponentBrochureAnchorAttributesFragment,
  ComponentBrochureBannerAttributesFragment,
  ComponentBrochureBlogListingAttributesFragment,
  ComponentBrochureButtonAttributesFragment,
  ComponentBrochureCardGridAttributesFragment,
  ComponentBrochureCardGridItemAttributesFragment,
  ComponentBrochureCarouselAttributesFragment,
  ComponentBrochureCarouselItemAttributesFragment,
  ComponentBrochureEventListingAttributesFragment,
  ComponentBrochureExpansionPanelAttributesFragment,
  ComponentBrochureFaqAttributesFragment,
  ComponentBrochureFaqItemAttributesFragment,
  ComponentBrochureHtmlContainerAttributesFragment,
  ComponentBrochureSectionOneAttributesFragment,
  ComponentBrochureSectionThreeAttributesFragment,
  ComponentBrochureSectionTwoAttributesFragment,
  ComponentBrochureSectionWithImageAttributesFragment,
  ComponentBrochureSpacerAttributesFragment,
  ComponentShopCatalogReelAttributesFragment,
  ComponentShopFooterLinksAttributesFragment,
  ComponentShopProductListingAttributesFragment,
} from '../gql/components.gql.generated';
import { UploadFileResponseFragment } from '../gql/media.gql.generated';
import { mapUrl } from './common.maps';
import {
  AnyBrochureComponentContent,
  AnyShopComponentContent,
  IAccordionContent,
  IActionBannerContent,
  IActionBannerItemContent,
  IActionGridContent,
  IActionGridItemContent,
  IActionListContent,
  IActionListItemContent,
  IBannerContent,
  IBlogListingContent,
  IButtonContent,
  ICardGridContent,
  ICardGridItemContent,
  ICarouselContent,
  ICarouselItemContent,
  ICatalogReelContent,
  IEventListingContent,
  IExpansionPanelContent,
  IFaqContent,
  IFaqItemContent,
  IFooterLinkCategoriesContent,
  IHtmlContainerContent,
  IProductListingContent,
  ISectionOneContent,
  ISectionThreeContent,
  ISectionTwoContent,
  ISectionWithImageContent,
  ISpacerContent,
} from './component.types';
import { mapUploadFileContent } from './media.maps';

export function mapActionBannerContent(
  source: ComponentBrochureActionBannerAttributesFragment,
): IActionBannerContent {
  return {
    ...source,
    actionBannerItems: source.actionBannerItems
      ? mapDefiniteArray(source.actionBannerItems).map(mapActionBannerItemContent)
      : undefined,
  };
}

export function mapActionBannerItemContent(
  source: ComponentBrochureActionBannerItemAttributesFragment,
): IActionBannerItemContent {
  return {
    ...source,
    media: source.media ? mapUploadFileContent(source.media) : undefined,
  };
}

export function mapActionGridContent(
  source: ComponentBrochureActionGridAttributesFragment,
): IActionGridContent {
  return {
    ...source,
    actionGridItems: mapDefiniteArray(source.actionGridItems).map(mapActionGridItemContent),
  };
}

export function mapActionGridItemContent(
  source: ComponentBrochureActionGridItemAttributesFragment,
): IActionGridItemContent {
  return {
    ...source,
    media: mapUploadFileContent(source.media),
  };
}

export function mapActionListContent(
  source: ComponentBrochureActionListAttributesFragment,
): IActionListContent {
  return {
    ...source,
    actionListItems: mapDefiniteArray(source.actionListItems).map(mapActionListItemContent),
  };
}

export function mapActionListItemContent(
  source: ComponentBrochureActionListItemAttributesFragment,
): IActionListItemContent {
  return {
    ...source,
    media: source.media ? mapUploadFileContent(source.media) : undefined,
  };
}

export function mapBannerContent(
  source: ComponentBrochureBannerAttributesFragment,
): IBannerContent {
  return {
    ...source,
    media: mapUploadFileContent(source.media),
    secondaryMedia: mapUploadFileContent(source.secondaryMedia as UploadFileResponseFragment),
    buttons: mapDefiniteArray(source.buttons ?? []),
  };
}

export function mapButtonContent(
  source: ComponentBrochureButtonAttributesFragment,
): IButtonContent {
  return source;
}

export function mapCardGridContent(
  source: ComponentBrochureCardGridAttributesFragment,
): ICardGridContent {
  return {
    ...source,
    cardItems: mapDefiniteArray(source.cardItems).map(mapCardGridItemContent),
  };
}

export function mapCardGridItemContent(
  source: ComponentBrochureCardGridItemAttributesFragment,
): ICardGridItemContent {
  return {
    ...source,
    url: mapUrl(source?.url),
    image: source.image ? mapUploadFileContent(source.image) : undefined,
  };
}

export function mapCarouselContent(
  source: ComponentBrochureCarouselAttributesFragment,
): ICarouselContent {
  return {
    ...source,
    carouselItems: mapDefiniteArray(source.carouselItems).map(mapCarouselItemContent),
  };
}

export function mapCarouselItemContent(
  source: ComponentBrochureCarouselItemAttributesFragment,
): ICarouselItemContent {
  return {
    ...source,
    media: mapUploadFileContent(source.media),
  };
}

export function mapFaqContent(source: ComponentBrochureFaqAttributesFragment): IFaqContent {
  return {
    ...source,
    faqItems: mapDefiniteArray(source.faqItems).map(mapFaqItemContent),
  };
}

export function mapFaqItemContent(
  source: ComponentBrochureFaqItemAttributesFragment,
): IFaqItemContent {
  return source;
}

export function mapSectionOneContent(
  source: ComponentBrochureSectionOneAttributesFragment,
): ISectionOneContent {
  return {
    ...source,
    buttons: mapDefiniteArray(source.buttons ?? []).map(mapButtonContent),
  };
}

export function mapSectionThreeContent(
  source: ComponentBrochureSectionThreeAttributesFragment,
): ISectionThreeContent {
  return {
    ...source,
    buttonOne: mapDefiniteArray(source.buttonOne ?? []).map(mapButtonContent),
    buttonTwo: mapDefiniteArray(source.buttonTwo ?? []).map(mapButtonContent),
    buttonThree: mapDefiniteArray(source.buttonThree ?? []).map(mapButtonContent),
  };
}

export function mapSectionTwoContent(
  source: ComponentBrochureSectionTwoAttributesFragment,
): ISectionTwoContent {
  return {
    ...source,
    buttonOne: mapDefiniteArray(source.buttonOne ?? []).map(mapButtonContent),
    buttonTwo: mapDefiniteArray(source.buttonTwo ?? []).map(mapButtonContent),
  };
}

export function mapSectionWithImageContent(
  source: ComponentBrochureSectionWithImageAttributesFragment,
): ISectionWithImageContent {
  return {
    ...source,
    buttons: mapDefiniteArray(source.buttons ?? []).map(mapButtonContent),
    media: mapUploadFileContent(source.media),
    secondaryMedia: mapUploadFileContent(source.secondaryMedia as UploadFileResponseFragment),
  };
}

export function mapEventListingContent(
  source: ComponentBrochureEventListingAttributesFragment,
): IEventListingContent {
  return source;
}

export function mapBlogListingContent(
  source: ComponentBrochureBlogListingAttributesFragment,
): IBlogListingContent {
  return source;
}

export function mapSpacerContent(
  source: ComponentBrochureSpacerAttributesFragment,
): ISpacerContent {
  return source;
}

export function mapProductListingContent(
  source: ComponentShopProductListingAttributesFragment,
): IProductListingContent {
  return {
    ...source,
    products: mapDefiniteArray(source?.products ?? []),
  };
}

export function mapHtmlContainerContent(
  source: ComponentBrochureHtmlContainerAttributesFragment,
): IHtmlContainerContent {
  return source;
}

export function mapFooterLinkCategoriesContent(
  source: ComponentShopFooterLinksAttributesFragment,
): IFooterLinkCategoriesContent {
  return {
    ...source,
    linkCategoryOne: mapDefiniteArray(source.linkCategoryOne ?? []).map(
      mapShopNavigationItemContent,
    ),
    linkCategoryTwo: mapDefiniteArray(source.linkCategoryTwo ?? []).map(
      mapShopNavigationItemContent,
    ),
    linkCategoryThree: mapDefiniteArray(source.linkCategoryThree ?? []).map(
      mapShopNavigationItemContent,
    ),
    linkCategoryFour: mapDefiniteArray(source.linkCategoryFour ?? []).map(
      mapShopNavigationItemContent,
    ),
  };
}

export function mapExpansionPanelContent(
  source: ComponentBrochureExpansionPanelAttributesFragment,
): IExpansionPanelContent {
  return source;
}

export function mapAccordionContent(
  source: ComponentBrochureAccordionAttributesFragment,
): IAccordionContent {
  return {
    ...source,
    expansionPanel: mapDefiniteArray(source.expansionPanel ?? []).map(mapExpansionPanelContent),
  };
}

export function mapCatalogReelContent(
  source: ComponentShopCatalogReelAttributesFragment,
): ICatalogReelContent {
  return {
    ...source,
    media: mapUploadFileContent(source.media),
    products: mapDefiniteArray(source?.products ?? []),
  };
}

export type BrochureComponentAttributesFragment =
  | ComponentBrochureActionBannerAttributesFragment
  | ComponentBrochureActionBannerItemAttributesFragment
  | ComponentBrochureActionGridAttributesFragment
  | ComponentBrochureActionGridItemAttributesFragment
  | ComponentBrochureActionListAttributesFragment
  | ComponentBrochureActionListItemAttributesFragment
  | ComponentBrochureBannerAttributesFragment
  | ComponentBrochureButtonAttributesFragment
  | ComponentBrochureCardGridAttributesFragment
  | ComponentBrochureCardGridItemAttributesFragment
  | ComponentBrochureCarouselAttributesFragment
  | ComponentBrochureCarouselItemAttributesFragment
  | ComponentBrochureEventListingAttributesFragment
  | ComponentBrochureBlogListingAttributesFragment
  | ComponentBrochureFaqAttributesFragment
  | ComponentBrochureFaqItemAttributesFragment
  | ComponentBrochureSectionOneAttributesFragment
  | ComponentBrochureSectionThreeAttributesFragment
  | ComponentBrochureSectionTwoAttributesFragment
  | ComponentBrochureSectionWithImageAttributesFragment
  | ComponentBrochureSpacerAttributesFragment
  | ComponentBrochureHtmlContainerAttributesFragment
  | ComponentBrochureAnchorAttributesFragment
  | ComponentBrochureAccordionAttributesFragment
  | ComponentBrochureExpansionPanelAttributesFragment
  | ErrorAttributesFragment;

export type ShopComponentAttributesFragment =
  | ComponentShopProductListingAttributesFragment
  | ComponentShopFooterLinksAttributesFragment
  | ComponentShopCatalogReelAttributesFragment;

export function mapDynamicComponent(
  component: BrochureComponentAttributesFragment | ShopComponentAttributesFragment,
): AnyBrochureComponentContent | AnyShopComponentContent | undefined {
  switch (component.__typename) {
    case 'ComponentBrochureBanner':
      return mapBannerContent(component);
    case 'ComponentBrochureSectionOne':
      return mapSectionOneContent(component);
    case 'ComponentBrochureSectionTwo':
      return mapSectionTwoContent(component);
    case 'ComponentBrochureSectionThree':
      return mapSectionThreeContent(component);
    case 'ComponentBrochureSectionWithImage':
      return mapSectionWithImageContent(component);
    case 'ComponentBrochureSpacer':
      return mapSpacerContent(component);
    case 'ComponentBrochureActionList':
      return mapActionListContent(component);
    case 'ComponentBrochureActionGrid':
      return mapActionGridContent(component);
    case 'ComponentBrochureActionBanner':
      return mapActionBannerContent(component);
    case 'ComponentBrochureFaq':
      return mapFaqContent(component);
    case 'ComponentBrochureCarousel':
      return mapCarouselContent(component);
    case 'ComponentBrochureCardGrid':
      return mapCardGridContent(component);
    case 'ComponentBrochureEventListing':
      return mapEventListingContent(component);
    case 'ComponentBrochureHtmlContainer':
      return mapHtmlContainerContent(component);
    case 'ComponentBrochureAccordion':
      return mapAccordionContent(component);
    case 'ComponentShopProductListing':
      return mapProductListingContent(component);
    case 'ComponentShopCatalogReel':
      return mapCatalogReelContent(component);
    case 'ComponentShopFooterLinkComponent':
      return mapFooterLinkCategoriesContent(component);
    case 'ComponentBrochureAnchor':
      return component;
  }

  console.warn(`mapBrochurePageDetailContent: Unknown component type ${component.__typename}`);

  return undefined;
}
