import { Injectable } from '@angular/core';
import { mapDefiniteArray } from '@x/common/utils';
import {
  ContentNotFoundException,
  IChannelPageDetailContent,
  IShopNavigationItemContent,
  mapChannelPageDetailContent,
  mapEntity,
  mapShopNavigationItemContent,
} from '@x/content/client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChannelNavigationByIdGQL,
  ChannelPageDetailByIdGQL,
} from './gql/channel-page.gql.generated';

@Injectable()
export class ChannelPageService {
  constructor(
    private ChannelPageDetailByIdGQL: ChannelPageDetailByIdGQL,
    private ChannelNavigationByIdGQL: ChannelNavigationByIdGQL,
  ) {}

  fetchDetail(id: string, locale: string): Observable<IChannelPageDetailContent> {
    return this.ChannelPageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.channelPage?.data;

        if (!data) {
          throw new ContentNotFoundException(`ChannelPage with id '${id}' not found.`);
        }

        return mapEntity(data);
      }),
      map(mapChannelPageDetailContent),
    );
  }

  fetchNavigation(id: string, locale: string): Observable<IShopNavigationItemContent[]> {
    return this.ChannelNavigationByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.channelPage?.data;

        if (!data) {
          throw new ContentNotFoundException(
            `Channel Navigation linked to Channel page with id '${id}' not found`,
          );
        }
        return mapEntity(data);
      }),
      map((r) => mapDefiniteArray(r.attributes?.navItems ?? []).map(mapShopNavigationItemContent)),
    );
  }
}
