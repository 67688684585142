import { mapDefiniteArray } from '@x/common/utils';
import { TaxonPageDetailAttributesFragment } from '../gql/taxon-page.gql.generated';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent, AnyShopComponentContent } from './component.types';
import { ITaxonPageDetailContent } from './taxon-page.types';

export function mapTaxonPageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: TaxonPageDetailAttributesFragment;
}): ITaxonPageDetailContent {
  return {
    ...attributes,
    id,
    taxonHeader: mapDefiniteArray(attributes.taxonHeader ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    taxonFooter: mapDefiniteArray(attributes.taxonFooter ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
  };
}
