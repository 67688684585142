import { mapDefiniteArray } from '@x/common/utils';
import { SubscriptionDetailAttributesFragment } from '../gql/subscription-detail.gql.generated';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent, AnyShopComponentContent } from './component.types';
import { ISubscriptionDetailContent } from './subscription-detail.types';

export function mapSubscriptionDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: SubscriptionDetailAttributesFragment;
}): ISubscriptionDetailContent {
  return {
    ...attributes,
    id,
    subscriptionHeader: mapDefiniteArray(attributes.subscriptionHeader ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    subscriptionFooter: mapDefiniteArray(attributes.subscriptionFooter ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    metaData: attributes.metaData ? mapMetaDataContent(attributes.metaData) : undefined,
  };
}
