import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { MembershipPageDetailByIdGQL } from './gql/membership-page.gql.generated';
import { mapEntity } from './types/common.maps';
import { mapMembershipPageDetailContent } from './types/membership-page.maps';
import { IMembershipPageDetailContent } from './types/membership-page.types';

@Injectable()
export class MembershipPageService {
  constructor(private MembershipPageDetailByIdGQL: MembershipPageDetailByIdGQL) {}

  fetchDetail(id: string, locale: string): Observable<IMembershipPageDetailContent> {
    return this.MembershipPageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.membershipPage?.data;

        if (!data) {
          throw new ContentNotFoundException(`MembershipPage with id '${id}' not found.`);
        }
        return mapEntity(data);
      }),
      map(mapMembershipPageDetailContent),
    );
  }
}
