export interface ContentErrorTranslation {
  title: string;
  message: string;
}

export type ContentErrorCodes =
  | 'auth.user_exists'
  | 'auth.invalid_credentials'
  | 'auth.unauthenticated'
  | 'icare.invalid_voucher_code'
  | 'payment_invalid_voucher_code'
  | 'INTERNAL_SERVER_ERROR'
  | 'entity_not_found';

export const CONTENT_ERROR_TRANSLATIONS: Record<ContentErrorCodes, ContentErrorTranslation> = {
  'auth.invalid_credentials': {
    title: $localize`:@@Error.Auth.InvalidCredentials.Title:Invalid Credentials`,
    message: $localize`:@@Error.Auth.InvalidCredentials.Message:Invalid email address or password. Please try again.`,
  },
  'auth.user_exists': {
    title: $localize`:@@Error.Auth.UserExists.Title:User Exists`,
    message: $localize`:@@Error.Auth.UserExists.Message:User already exists. Try and login instead.`,
  },
  'auth.unauthenticated': {
    title: $localize`:@@Error.Auth.Unauthenticated.Title:User Unauthenticated`,
    message: $localize`:@@Error.Auth.Unauthenticated.Message:Can’t perform this action at this point. User Unauthenticated.`,
  },
  'icare.invalid_voucher_code': {
    title: $localize`:@@Error.Icare.InvalidVoucherCode.Title:Invalid Voucher Code`,
    message: $localize`:@@Error.Icare.InvalidVoucherCode.Message:Please use a valid voucher code.`,
  },
  payment_invalid_voucher_code: {
    title: $localize`:@@Error.PaymentInvalidVoucherCode.Title:Invalid Voucher Code`,
    message: $localize`:@@Error.PaymentInvalidVoucherCode.Message:Please user a valid voucher code.`,
  },
  INTERNAL_SERVER_ERROR: {
    title: $localize`:@@Error.GenericError.Title:Oops! Something went wrong`,
    message: $localize`:@@Error.GenericError.Message:Please try again or contact us.`,
  },
  entity_not_found: {
    title: $localize`:@@Error.EntityNotFound.Title:Entity not found.`,
    message: $localize`:@@Error.EntityNotFound.Message:Invalid enity id.`,
  },
};
