import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChannelPageService } from './channel-page.service';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { LandingPageService } from './landing-page.service';
import { MembershipPageService } from './membership-page.service';
import { ProductPageService } from './product-page.service';
import { SubscriptionDetailService } from './subscription-detail.service';
import { TaxonPageService } from './taxon-page.service';
import { IChannelPageDetailContent } from './types/channel-page.types';
import { IShopNavigationItemContent } from './types/component.types';
import { ILandingPageDetailContent } from './types/landing-page.types';
import { IMembershipPageDetailContent } from './types/membership-page.types';
import { IProductPageDetailContent } from './types/product-page.types';
import { ISubscriptionDetailContent } from './types/subscription-detail.types';
import { ITaxonPageDetailContent } from './types/taxon-page.types';

interface IContentProviderRefConstruct {
  providerId: string;
  contentRef: string;
}

interface IContentRefConsruct {
  contentType: string;
  id: string;
}

type ContentRefType =
  | IProductPageDetailContent
  | IChannelPageDetailContent
  | IShopNavigationItemContent[]
  | ITaxonPageDetailContent
  | ILandingPageDetailContent
  | ISubscriptionDetailContent
  | IMembershipPageDetailContent;

@Injectable()
export class ContentRefService {
  constructor(
    private productPageService: ProductPageService,
    private channelPageService: ChannelPageService,
    private taxonPageService: TaxonPageService,
    private landingPageService: LandingPageService,
    private membershipPageService: MembershipPageService,
    private subscriptionDetailService: SubscriptionDetailService,
  ) {}

  fetchRef<T = ContentRefType>(ref: string, locale: string) {
    const { contentRef, providerId } = this.parseRef(ref);
    const { id, contentType } = this.deconstructRef(contentRef);

    switch (contentType) {
      case 'product-pages':
        return this.castResult<T>(this.productPageService.fetchDetail(id, locale));
      case 'channel-pages':
        return this.castResult<T>(this.channelPageService.fetchDetail(id, locale));
      case 'taxon-pages':
        return this.castResult<T>(this.taxonPageService.fetchDetail(id, locale));
      case 'landing-pages':
        return this.castResult<T>(this.landingPageService.fetchDetail(id, locale));
      case 'membership-pages':
        return this.castResult<T>(this.membershipPageService.fetchDetail(id, locale));
      case 'subscription-pages':
        return this.castResult<T>(this.subscriptionDetailService.fetchDetail(id, locale));
    }

    throw new ContentNotFoundException(
      `ContentRef for provider '${providerId}' with contentType '${contentType}', id '${id}', locale '${locale}' not found.`,
    );
  }

  private castResult<T = ContentRefType>(detail: Observable<ContentRefType>) {
    // TODO find a cleaner solution for this
    return detail as unknown as Observable<T>;
  }

  private parseRef(ref: string): IContentProviderRefConstruct {
    const split = ref.split(':');
    return {
      providerId: split[0],
      contentRef: split[1],
    };
  }

  private deconstructRef(ref: string): IContentRefConsruct {
    const split = ref.split('/');
    return {
      contentType: split[0],
      id: split[1],
    };
  }
}
