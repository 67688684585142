import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { ProductPageDetailByIdGQL } from './gql/product-page.gql.generated';
import { mapEntity } from './types/common.maps';
import { mapProductPageDetailContent } from './types/product-page.maps';
import { IProductPageDetailContent } from './types/product-page.types';

@Injectable()
export class ProductPageService {
  constructor(private ProductPageDetailByIdGQL: ProductPageDetailByIdGQL) {}

  fetchDetail(id: string, locale: string): Observable<IProductPageDetailContent> {
    return this.ProductPageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.productPage?.data;

        if (!data) {
          throw new ContentNotFoundException(`ProductPage with id '${id}' not found.`);
        }

        return mapEntity(data);
      }),
      map(mapProductPageDetailContent),
    );
  }
}
