import { mapDefiniteArray } from '@x/common/utils';
import { EventIndexAttributesFragment } from '../gql/event-index.gql.generated';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent } from './component.types';
import { IEventIndexContent } from './event-index.types';

export function mapEventIndexContent(source: EventIndexAttributesFragment): IEventIndexContent {
  return {
    ...source,
    header: source.header
      ? (mapDefiniteArray(source.header)
          .map(mapDynamicComponent)
          .filter((c) => !!c) as AnyBrochureComponentContent[])
      : undefined,
    metaData: source.metaData ? mapMetaDataContent(source.metaData) : undefined,
  };
}
