import { MetaDataAttributesFragment } from '../gql/common.gql.generated';
import { IMetaDataContent, IUrlContent } from './common.types';
import { mapUploadFileContent } from './media.maps';

export function mapMetaDataContent(source: MetaDataAttributesFragment): IMetaDataContent {
  return {
    ...source,
    metaImage: source.metaImage ? mapUploadFileContent(source.metaImage) : undefined,
  };
}

export function mapEntity<A>(data: { id?: string | null; attributes?: A | null }): {
  id: string;
  attributes: A;
} {
  return data as { id: string; attributes: A };
}

export function mapUrl(url?: string | null): IUrlContent | null {
  const [urlWithoutFragment, fragment] = url?.split('#') || [];

  return url
    ? {
        url,
        urlWithoutFragment,
        fragment,
      }
    : null;
}
