import { mapDefiniteArray } from '@x/common/utils';
import {
  BrochurePageDetailAttributesFragment,
  BrochurePageItemAttributesFragment,
} from '../gql/brochure-page.gql.generated';
import { IBrochurePageDetailContent, IBrochurePageItemContent } from './brochure-page.types';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent } from './component.types';

export function mapBrochurePageItemContent({
  id,
  attributes,
}: {
  id: string;
  attributes: BrochurePageItemAttributesFragment;
}): IBrochurePageItemContent {
  return {
    ...attributes,
    id,
  };
}

export function mapBrochurePageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: BrochurePageDetailAttributesFragment;
}): IBrochurePageDetailContent {
  return {
    ...attributes,
    id,
    body: mapDefiniteArray(attributes.body)
      .map(mapDynamicComponent)
      .filter((c) => !!c) as AnyBrochureComponentContent[],
    metaData: attributes.metaData ? mapMetaDataContent(attributes.metaData) : undefined,
  };
}
