import {
  BlogPageDetailAttributesFragment,
  BlogPageItemAttributesFragment,
} from '../gql/blog-page.gql.generated';
import { IBlogPageDetailContent, IBlogPageItemContent } from './blog-page.types';
import { mapUploadFileContent } from './media.maps';

export function mapBlogPageItemContent({
  id,
  attributes,
}: {
  id: string;
  attributes: BlogPageItemAttributesFragment;
}): IBlogPageItemContent {
  return {
    ...attributes,
    id,
    image: attributes.image ? mapUploadFileContent(attributes.image) : undefined,
  };
}

export function mapBlogPageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: BlogPageDetailAttributesFragment;
}): IBlogPageDetailContent {
  return {
    ...attributes,
    id,
    image: attributes.image ? mapUploadFileContent(attributes.image) : undefined,
  };
}
