import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { BrochureHomeGQL } from './gql/brochure-home.gql.generated';
import {
  BrochurePageDetailByIdGQL,
  BrochurePageItemAttributesFragment,
  BrochurePageItemsGQL,
} from './gql/brochure-page.gql.generated';
import { mapBrochureHomeContent } from './types/brochure-home.maps';
import { IBrochureHomeContent } from './types/brochure-home.types';
import {
  mapBrochurePageDetailContent,
  mapBrochurePageItemContent,
} from './types/brochure-page.maps';
import { IBrochurePageDetailContent, IBrochurePageItemContent } from './types/brochure-page.types';
import { mapCollection } from './types/collection.maps';
import { ContentCollection } from './types/collection.types';
import { mapEntity } from './types/common.maps';
import { StrapiCollection } from './types/common.types';

@Injectable()
export class BrochurePageService {
  constructor(
    private BrochurePageItemsGQL: BrochurePageItemsGQL,
    private BrochurePageDetailByIdGQL: BrochurePageDetailByIdGQL,
    private BrochureHomeGQL: BrochureHomeGQL,
  ) {}

  fetchHome(locale: string): Observable<IBrochureHomeContent> {
    return this.BrochureHomeGQL.fetch({ locale: locale }).pipe(
      tap((res) => console.log('GRAPH', res)),
      map((r) => {
        const page = r.data.brochureHome?.data?.attributes;

        if (!page) {
          throw new ContentNotFoundException(`BrochureHome (locale=${locale}) page not found.`);
        }

        return page;
      }),
      map(mapBrochureHomeContent),
    );
  }

  fetchItems(): Observable<ContentCollection<IBrochurePageItemContent>> {
    return this.BrochurePageItemsGQL.fetch().pipe(
      map((r) => r.data.brochurePages as StrapiCollection<BrochurePageItemAttributesFragment>),
      mapCollection(mapBrochurePageItemContent),
    );
  }

  fetchDetail(id: string, locale: string): Observable<IBrochurePageDetailContent> {
    return this.BrochurePageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const page = r.data.brochurePage?.data;

        if (!page) {
          throw new ContentNotFoundException(
            `BrochurePage with id '${id}', locale '${locale}' not found.`,
          );
        }

        return mapEntity(page);
      }),
      map(mapBrochurePageDetailContent),
    );
  }
}
