import {
  EventPageDetailAttributesFragment,
  EventPageItemAttributesFragment,
} from '../gql/event-page.gql.generated';
import { mapMetaDataContent } from './common.maps';
import { IEventPageDetailContent, IEventPageItemContent } from './event-page.types';
import { mapUploadFileContent } from './media.maps';

export function mapEventPageItemContent({
  id,
  attributes,
}: {
  id: string;
  attributes: EventPageItemAttributesFragment;
}): IEventPageItemContent {
  return {
    ...attributes,
    id,
    image: attributes.image ? mapUploadFileContent(attributes.image) : undefined,
    banner: attributes.banner ? mapUploadFileContent(attributes.banner) : undefined,
  };
}

export function mapEventPageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: EventPageDetailAttributesFragment;
}): IEventPageDetailContent {
  return {
    ...attributes,
    id,
    image: attributes.image ? mapUploadFileContent(attributes.image) : undefined,
    banner: attributes.banner ? mapUploadFileContent(attributes.banner) : undefined,
    metaData: attributes.metaData ? mapMetaDataContent(attributes.metaData) : undefined,
  };
}
