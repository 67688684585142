import { ModuleWithProviders, NgModule } from '@angular/core';
import { ERROR_TRANSLATOR } from '@x/common/error';
import { GRAPH_CLIENT } from '@x/common/graph';
import { ContentClientModuleConfig } from './content-client.config';
import { ContentClientErrorTranslator } from './content-client.error-translator';
import { BlogPageService } from './services/blog-page.service';
import { BrochureNavCategoryService } from './services/brochure-nav-category.service';
import { BrochureNavItemsService } from './services/brochure-nav-item.service';
import { BrochurePageService } from './services/brochure-page.service';
import { ChannelPageService } from './services/channel-page.service';
import { ContentRefService } from './services/content-ref.service';
import { EventPageService } from './services/event-page.service';
import { LandingPageService } from './services/landing-page.service';
import { MembershipPageService } from './services/membership-page.service';
import { ProductPageService } from './services/product-page.service';
import { SubscriptionDetailService } from './services/subscription-detail.service';
import { TaxonPageService } from './services/taxon-page.service';

@NgModule({})
export class ContentClientModule {
  static forRoot(config: ContentClientModuleConfig): ModuleWithProviders<ContentClientModule> {
    return {
      ngModule: ContentClientModule,
      providers: [
        {
          provide: ERROR_TRANSLATOR,
          useClass: ContentClientErrorTranslator,
          multi: true,
        },
        BlogPageService,
        BrochurePageService,
        EventPageService,
        ProductPageService,
        ChannelPageService,
        TaxonPageService,
        LandingPageService,
        MembershipPageService,
        BrochureNavItemsService,
        BrochureNavCategoryService,
        SubscriptionDetailService,
        ContentRefService,
        {
          provide: GRAPH_CLIENT,
          useValue: {
            name: 'content',
            uri: config.graphUrl,
            defaultOptions: {
              query: { fetchPolicy: 'no-cache', errorPolicy: 'none', returnPartialData: false },
            },
          },
          multi: true,
        },
      ],
    };
  }
}
