import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { TaxonPageDetailByIdGQL } from './gql/taxon-page.gql.generated';
import { mapEntity } from './types/common.maps';
import { mapTaxonPageDetailContent } from './types/taxon-page.maps';
import { ITaxonPageDetailContent } from './types/taxon-page.types';

@Injectable()
export class TaxonPageService {
  constructor(private TaxonPageDetailByIdGQL: TaxonPageDetailByIdGQL) {}

  fetchDetail(id: string, locale: string): Observable<ITaxonPageDetailContent> {
    return this.TaxonPageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.taxonPage?.data;

        if (!data) {
          throw new ContentNotFoundException(`TaxonPage with id '${id}' not found.`);
        }
        return mapEntity(data);
      }),
      map(mapTaxonPageDetailContent),
    );
  }
}
