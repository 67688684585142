import { getMediaTypeFromMime } from '@x/common/utils';
import { UploadFileResponseFragment } from '../gql/media.gql.generated';
import { IUploadFileContent } from './media.types';

export function mapUploadFileContent(
  source: UploadFileResponseFragment,
): IUploadFileContent | undefined {
  if (!source.data) return undefined;
  if (!source.data.attributes) return undefined;
  return {
    ...source.data.attributes,
    type: getMediaTypeFromMime(source.data.attributes.mime),
  };
}
