import * as Types from '@x/schemas/content';

import { gql } from 'apollo-angular';
import { BrochureNavCategoryResponseFragmentDoc } from './brochure-nav-category.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BrochureNavItemDetailAttributesFragment = { __typename: 'BrochureNavItem', text?: string | null | undefined, url?: string | null | undefined, openInNewTab?: boolean | null | undefined, linkPosition: Types.Enum_Brochurenavitem_Linkposition, brochureNavCategory?: { __typename: 'BrochureNavCategoryEntityResponse', data?: { __typename: 'BrochureNavCategoryEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavCategory', categoryName: string } | null | undefined } | null | undefined } | null | undefined };

export type BrochureNavItemsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BrochureNavItemsQuery = { __typename: 'Query', brochureNavItems?: { __typename: 'BrochureNavItemEntityResponseCollection', data: Array<{ __typename: 'BrochureNavItemEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavItem', text?: string | null | undefined, url?: string | null | undefined, openInNewTab?: boolean | null | undefined, linkPosition: Types.Enum_Brochurenavitem_Linkposition, brochureNavCategory?: { __typename: 'BrochureNavCategoryEntityResponse', data?: { __typename: 'BrochureNavCategoryEntity', id?: string | null | undefined, attributes?: { __typename: 'BrochureNavCategory', categoryName: string } | null | undefined } | null | undefined } | null | undefined } | null | undefined }> } | null | undefined };

export const BrochureNavItemDetailAttributesFragmentDoc = gql`
    fragment BrochureNavItemDetailAttributes on BrochureNavItem {
  brochureNavCategory {
    ...BrochureNavCategoryResponse
  }
  text
  url
  openInNewTab
  linkPosition
}
    ${BrochureNavCategoryResponseFragmentDoc}`;
export const BrochureNavItemsDocument = gql`
    query BrochureNavItems {
  brochureNavItems {
    data {
      id
      attributes {
        ...BrochureNavItemDetailAttributes
      }
    }
  }
}
    ${BrochureNavItemDetailAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrochureNavItemsGQL extends Apollo.Query<BrochureNavItemsQuery, BrochureNavItemsQueryVariables> {
    document = BrochureNavItemsDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }