import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { SubscriptionDetailByIdGQL } from './gql/subscription-detail.gql.generated';
import { mapEntity } from './types/common.maps';
import { mapSubscriptionDetailContent } from './types/subscription-detail.maps';
import { ISubscriptionDetailContent } from './types/subscription-detail.types';

@Injectable()
export class SubscriptionDetailService {
  constructor(private SubscriptionDetailByIdGQL: SubscriptionDetailByIdGQL) {}

  fetchDetail(id: string, locale: string): Observable<ISubscriptionDetailContent> {
    return this.SubscriptionDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.subscriptionPage?.data;

        if (!data) {
          throw new ContentNotFoundException(`Subscription Detail with id '${id}' not found.`);
        }
        return mapEntity(data);
      }),
      map(mapSubscriptionDetailContent),
    );
  }
}
