import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BrochureNavItemsGQL } from './gql/brochure-nav-item.gql.generated';
import { IBrochureNavItemContent, IBrochureNavLinks } from './types/brochure-nav-item-types';

@Injectable()
export class BrochureNavItemsService {
  constructor(private BrochureNavItemsGQL: BrochureNavItemsGQL) {}

  fetchNavLinks(): Observable<IBrochureNavLinks> {
    return this.BrochureNavItemsGQL.fetch().pipe(
      switchMap((data) => {
        const toolbarLinks: IBrochureNavItemContent[] = [];
        const toolbarL = data.data.brochureNavItems?.data.filter(
          (item) => item.attributes?.linkPosition === 'Toolbar',
        );
        toolbarL?.forEach((item) => {
          toolbarLinks.push(item.attributes as IBrochureNavItemContent);
        });

        //SidebarFooter Links
        const sidebarFooterLinks: IBrochureNavItemContent[] = [];
        const sidebarFooterL = data.data.brochureNavItems?.data.filter(
          (item) => item.attributes?.linkPosition === 'Sidebar_Footer',
        );
        sidebarFooterL?.forEach((item) => {
          sidebarFooterLinks.push(item.attributes as IBrochureNavItemContent);
        });

        return of({
          toolbarLinks: toolbarLinks,
          sidenavFooterLinks: sidebarFooterLinks,
        } as IBrochureNavLinks);
      }),
    );
  }
}
