import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { BlogIndexGQL } from './gql/blog-index.gql.generated';
import {
  BlogPageDetailByIdGQL,
  BlogPageItemAttributesFragment,
  BlogPageItemsGQL,
} from './gql/blog-page.gql.generated';
import { mapBlogIndexContent } from './types/blog-index.maps';
import { IBlogIndexContent } from './types/blog-index.types';
import { mapBlogPageDetailContent, mapBlogPageItemContent } from './types/blog-page.maps';
import { IBlogPageDetailContent, IBlogPageItemContent } from './types/blog-page.types';
import { mapCollection } from './types/collection.maps';
import { ContentCollection, ContentQuery } from './types/collection.types';
import { mapEntity } from './types/common.maps';
import { StrapiCollection } from './types/common.types';

@Injectable()
export class BlogPageService {
  constructor(
    private BlogPageItemsGQL: BlogPageItemsGQL,
    private BlogPageDetailByIdGQL: BlogPageDetailByIdGQL,
    private BlogIndexGQL: BlogIndexGQL,
    private apollo: Apollo,
  ) {}

  fetchIndex(locale: string): Observable<IBlogIndexContent> {
    return this.BlogIndexGQL.fetch({ locale }).pipe(
      map((r) => {
        const page = r.data.blogIndex?.data?.attributes;

        if (!page) {
          throw new ContentNotFoundException(`BlogIndex not found.`);
        }

        return page;
      }),
      map(mapBlogIndexContent),
    );
  }

  fetchItems(query: ContentQuery): Observable<ContentCollection<IBlogPageItemContent>> {
    return this.BlogPageItemsGQL.fetch({ ...query }).pipe(
      map((r) => r.data.blogPages as StrapiCollection<BlogPageItemAttributesFragment>),
      mapCollection(mapBlogPageItemContent),
    );
  }

  fetchDetail(id: string, locale: string): Observable<IBlogPageDetailContent> {
    return this.BlogPageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const data = r.data.blogPage?.data;

        if (!data) {
          throw new ContentNotFoundException(`BlogPage with id '${id}' not found.`);
        }

        return mapEntity(data);
      }),
      map(mapBlogPageDetailContent),
    );
  }
}
