import * as Types from '@x/schemas/content';

import { gql } from 'apollo-angular';
import { UploadFileResponseFragmentDoc } from './media.gql.generated';
import { MetaDataAttributesFragmentDoc } from './common.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BlogPageItemAttributesFragment = { __typename: 'BlogPage', title: string, slug: string, locale?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, publishedAt?: any | null | undefined, image?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type BlogPageDetailAttributesFragment = { __typename: 'BlogPage', title: string, slug: string, locale?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, publishedAt?: any | null | undefined, body: string, image?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined, metaData: { __typename: 'ComponentCommonMetaData', metaTitle: string, metaDescription?: string | null | undefined, metaKeywords?: string | null | undefined, metaImage?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined } };

export type BlogPageDetailByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']['input']>;
}>;


export type BlogPageDetailByIdQuery = { __typename: 'Query', blogPage?: { __typename: 'BlogPageEntityResponse', data?: { __typename: 'BlogPageEntity', id?: string | null | undefined, attributes?: { __typename: 'BlogPage', title: string, slug: string, locale?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, publishedAt?: any | null | undefined, body: string, image?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined, metaData: { __typename: 'ComponentCommonMetaData', metaTitle: string, metaDescription?: string | null | undefined, metaKeywords?: string | null | undefined, metaImage?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined } } | null | undefined } | null | undefined } | null | undefined };

export type BlogPageItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BlogPageFiltersInput>;
  page?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']['input']>;
}>;


export type BlogPageItemsQuery = { __typename: 'Query', blogPages?: { __typename: 'BlogPageEntityResponseCollection', data: Array<{ __typename: 'BlogPageEntity', id?: string | null | undefined, attributes?: { __typename: 'BlogPage', title: string, slug: string, locale?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, publishedAt?: any | null | undefined, image?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined } | null | undefined }>, meta: { __typename: 'ResponseCollectionMeta', pagination: { __typename: 'Pagination', total: number } } } | null | undefined };

export const BlogPageItemAttributesFragmentDoc = gql`
    fragment BlogPageItemAttributes on BlogPage {
  title
  slug
  locale
  createdAt
  updatedAt
  publishedAt
  image {
    ...UploadFileResponse
  }
}
    ${UploadFileResponseFragmentDoc}`;
export const BlogPageDetailAttributesFragmentDoc = gql`
    fragment BlogPageDetailAttributes on BlogPage {
  title
  slug
  locale
  createdAt
  updatedAt
  publishedAt
  body
  image {
    ...UploadFileResponse
  }
  metaData {
    ...MetaDataAttributes
  }
}
    ${UploadFileResponseFragmentDoc}
${MetaDataAttributesFragmentDoc}`;
export const BlogPageDetailByIdDocument = gql`
    query BlogPageDetailById($id: ID!, $locale: I18NLocaleCode) {
  blogPage(id: $id, locale: $locale) {
    data {
      id
      attributes {
        ...BlogPageDetailAttributes
      }
    }
  }
}
    ${BlogPageDetailAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogPageDetailByIdGQL extends Apollo.Query<BlogPageDetailByIdQuery, BlogPageDetailByIdQueryVariables> {
    document = BlogPageDetailByIdDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlogPageItemsDocument = gql`
    query BlogPageItems($filter: BlogPageFiltersInput, $page: PaginationArg, $sort: [String!], $locale: I18NLocaleCode) {
  blogPages(filters: $filter, pagination: $page, sort: $sort, locale: $locale) {
    data {
      id
      attributes {
        ...BlogPageItemAttributes
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${BlogPageItemAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogPageItemsGQL extends Apollo.Query<BlogPageItemsQuery, BlogPageItemsQueryVariables> {
    document = BlogPageItemsDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }