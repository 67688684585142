import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BrochureNavCategoriesGQL } from './gql/brochure-nav-category.gql.generated';
import { IBrochureNavCategoryContent } from './types/brochure-nav-category.types';
import { IBrochureNavItemContent } from './types/brochure-nav-item-types';

@Injectable()
export class BrochureNavCategoryService {
  constructor(private BrochureNavCategoriesGQL: BrochureNavCategoriesGQL) {}

  fetchNavCategories(): Observable<IBrochureNavCategoryContent[]> {
    return this.BrochureNavCategoriesGQL.fetch().pipe(
      switchMap((data) => {
        const categories: IBrochureNavCategoryContent[] = [];
        const nav = data.data.brochureNavCategories?.data;
        nav?.forEach((item) => {
          let navLinks: IBrochureNavItemContent[] = [];
          item.attributes?.brochureNavItems?.data.forEach((i) => {
            navLinks.push(i.attributes as IBrochureNavItemContent);
          });

          categories.push({
            categoryName: item.attributes?.categoryName,
            brochure_nav_items: navLinks,
          } as IBrochureNavCategoryContent);
        });
        return of(categories);
      }),
    );
  }
}
