import { ApolloError, isApolloError } from '@apollo/client/core';
import { ErrorCode, ErrorTranslator, TranslatedError } from '@x/common/error';
import { GraphQLError } from 'graphql';

export class ApolloErrorTranslator implements ErrorTranslator {
  canTranslate(error: any): boolean {
    return (error instanceof ApolloError || isApolloError(error)) && !error.networkError;
  }
  translateError(error: any): TranslatedError {
    if (isApolloError(error)) {
      if (error.graphQLErrors.length) {
        return this.translateGraphQLError(error.graphQLErrors[0]);
      }
    }

    return {
      title: 'Apollo Error',
      code: ErrorCode.ERROR,
      message: 'Apollo error occured',
    };
  }

  translateGraphQLError(graphQlError: GraphQLError): TranslatedError {
    // Error Codes
    // GRAPHQL_PARSE_FAILED 'Syntax Error: Expected Name, found \"}\"'

    const code = (graphQlError.extensions?.code as string) ?? graphQlError.message;

    return {
      title: `GraphQL Error`,
      code,
      message: `Error occured during GraphQL execution.`,
      detail: JSON.stringify(graphQlError, undefined, 2),
    };
  }
}
