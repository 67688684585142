import { mapDefiniteArray } from '@x/common/utils';
import { MembershipPageDetailAttributesFragment } from '../gql/membership-page.gql.generated';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent, AnyShopComponentContent } from './component.types';
import { IMembershipPageDetailContent } from './membership-page.types';

export function mapMembershipPageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: MembershipPageDetailAttributesFragment;
}): IMembershipPageDetailContent {
  return {
    ...attributes,
    id,
    membershipHeader: mapDefiniteArray(attributes.membershipHeader ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    membershipFooter: mapDefiniteArray(attributes.membershipFooter ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    metaData: attributes.metaData ? mapMetaDataContent(attributes.metaData) : undefined,
  };
}
