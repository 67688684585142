import { mapDefiniteArray } from '@x/common/utils';
import { BrochureHomeAttributesFragment } from '../gql/brochure-home.gql.generated';
import { IBrochureHomeContent } from './brochure-home.types';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent } from './component.types';

export function mapBrochureHomeContent(
  source: BrochureHomeAttributesFragment,
): IBrochureHomeContent {
  return {
    ...source,
    body: mapDefiniteArray(source.body)
      .map(mapDynamicComponent)
      .filter((c) => !!c) as AnyBrochureComponentContent[],
    metaData: source.metaData ? mapMetaDataContent(source.metaData) : null,
  };
}
