import { mapDefiniteArray } from '@x/common/utils';
import { ChannelPageDetailAttributesFragment } from '../gql/channel-page.gql.generated';
import { ComponentShopNavigationItemAttributesFragment } from '../gql/components.gql.generated';
import { IChannelPageDetailContent } from './channel-page.types';
import { mapMetaDataContent } from './common.maps';
import { mapDynamicComponent } from './component.maps';
import {
  AnyBrochureComponentContent,
  AnyShopComponentContent,
  IShopNavigationItemContent,
} from './component.types';
import { mapUploadFileContent } from './media.maps';

export function mapChannelPageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: ChannelPageDetailAttributesFragment;
}): IChannelPageDetailContent {
  return {
    ...attributes,
    id,
    body: mapDefiniteArray(attributes.body ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    navItems: mapDefiniteArray(attributes.navItems ?? []).map(mapShopNavigationItemContent),
    channelFooter: mapDefiniteArray(attributes.channelFooter ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as (AnyBrochureComponentContent | AnyShopComponentContent)[],
    channelNotification: attributes.channelNotification,
    metaData: attributes.metaData ? mapMetaDataContent(attributes.metaData) : undefined,
  };
}

export function mapShopNavigationItemContent(
  attributes: ComponentShopNavigationItemAttributesFragment,
): IShopNavigationItemContent {
  return {
    ...attributes,
    media: attributes.media ? mapUploadFileContent(attributes.media) : undefined,
  };
}
