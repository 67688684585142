import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { LandingPageDetailByIdGQL, LandingPageItemsGQL } from './gql/landing-page.gql.generated';
import { mapEntity } from './types/common.maps';
import { mapLandingPageDetailContent } from './types/landing-page.maps';
import { ILandingPageDetailContent } from './types/landing-page.types';

@Injectable()
export class LandingPageService {
  constructor(
    private LandingPageItemsGQL: LandingPageItemsGQL,
    private LandingPageDetailByIdGQL: LandingPageDetailByIdGQL,
  ) {}

  // fetchItems(): Observable<ContentCollection<ILandingPageItemContent>> {
  //   return this.LandingPageItemsGQL.fetch().pipe(
  //     map((r) => r.data.landingPages as StrapiCollection<LandingPageItemAttributesFragment>),
  //     mapCollection(mapLandingPageItemContent),
  //   );
  // }

  fetchDetail(id: string, locale: string): Observable<ILandingPageDetailContent> {
    return this.LandingPageDetailByIdGQL.fetch({ id, locale: locale }).pipe(
      map((r) => {
        const page = r.data.landingPage?.data;

        if (!page) {
          throw new ContentNotFoundException(
            `LandingPage with id '${id}', locale '${locale}' not found.`,
          );
        }

        return mapEntity(page);
      }),
      map(mapLandingPageDetailContent),
    );
  }
}
