import * as Types from '@x/schemas/content';

import { gql } from 'apollo-angular';
export type UploadFileAttributesFragment = { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined };

export type UploadFileResponseFragment = { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined };

export const UploadFileAttributesFragmentDoc = gql`
    fragment UploadFileAttributes on UploadFile {
  name
  alternativeText
  caption
  width
  height
  hash
  ext
  mime
  size
  url
  createdAt
  updatedAt
}
    `;
export const UploadFileResponseFragmentDoc = gql`
    fragment UploadFileResponse on UploadFileEntityResponse {
  data {
    id
    attributes {
      ...UploadFileAttributes
    }
  }
}
    ${UploadFileAttributesFragmentDoc}`;