import { mapDefiniteArray } from '@x/common/utils';
import { ProductPageDetailAttributesFragment } from '../gql/product-page.gql.generated';
import { mapDynamicComponent } from './component.maps';
import { AnyBrochureComponentContent, AnyShopComponentContent } from './component.types';
import { IProductPageDetailContent } from './product-page.types';

export function mapProductPageDetailContent({
  id,
  attributes,
}: {
  id: string;
  attributes: ProductPageDetailAttributesFragment;
}): IProductPageDetailContent {
  return {
    ...attributes,
    id,
    productHeader: mapDefiniteArray(attributes.productHeader ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as Array<AnyBrochureComponentContent | AnyShopComponentContent>,
    productFooter: mapDefiniteArray(attributes.productFooter ?? [])
      .map(mapDynamicComponent)
      .filter((c) => !!c) as Array<AnyBrochureComponentContent | AnyShopComponentContent>,
  };
}
