import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { EventIndexGQL } from './gql/event-index.gql.generated';
import {
  EventPageDetailByIdGQL,
  EventPageItemAttributesFragment,
  EventPageItemsGQL,
} from './gql/event-page.gql.generated';
import { mapCollection } from './types/collection.maps';
import { ContentCollection } from './types/collection.types';
import { mapEntity } from './types/common.maps';
import { ICollectionFilter, StrapiCollection } from './types/common.types';
import { mapEventIndexContent } from './types/event-index.maps';
import { IEventIndexContent } from './types/event-index.types';
import { mapEventPageDetailContent, mapEventPageItemContent } from './types/event-page.maps';
import { IEventPageDetailContent, IEventPageItemContent } from './types/event-page.types';

@Injectable()
export class EventPageService {
  constructor(
    private EventPageItemsGQL: EventPageItemsGQL,
    private EventPageDetailByIdGQL: EventPageDetailByIdGQL,
    private EventIndexGQL: EventIndexGQL,
    @Inject(LOCALE_ID)
    private locale: string,
  ) {}

  fetchIndex(): Observable<IEventIndexContent> {
    return this.EventIndexGQL.fetch({ locale: this.locale }).pipe(
      map((r) => {
        const page = r.data.eventIndex?.data?.attributes;

        if (!page) {
          throw new ContentNotFoundException(`EventIndex ${this.locale} not found.`);
        }

        return page;
      }),
      map(mapEventIndexContent),
    );
  }

  fetchItems(
    filter: ICollectionFilter,
    page: number = 0,
  ): Observable<ContentCollection<IEventPageItemContent>> {
    return this.EventPageItemsGQL.fetch({
      pagination: { pageSize: filter.pageSize ?? 10, page },
      filters: filter.filter,
      locale: this.locale,
      sort: `${filter.sortColumn ?? 'updatedAt'}:${filter.sortOrder ?? 'desc'}`,
    }).pipe(
      map((r) => r.data.eventPages as StrapiCollection<EventPageItemAttributesFragment>),
      mapCollection(mapEventPageItemContent),
    );
  }

  fetchDetail(id: string, locale?: string): Observable<IEventPageDetailContent> {
    return this.EventPageDetailByIdGQL.fetch({ id, locale: locale ?? this.locale }).pipe(
      map((r) => {
        const page = r.data.eventPage?.data;

        if (!page) {
          throw new ContentNotFoundException(`EventPage with id '${id}' not found.`);
        }

        return mapEntity(page);
      }),
      map(mapEventPageDetailContent),
    );
  }
}
