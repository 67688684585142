import * as Types from '@x/schemas/content';

import { gql } from 'apollo-angular';
import { UploadFileResponseFragmentDoc } from './media.gql.generated';
export type CollectionFilterFragment = { __typename: 'ComponentCommonCollectionFilter', sortColumn: string, sortOrder: Types.CollectionSortOrder, pageSize: number, filter?: any | null | undefined };

export type ProductCollectionFilterFragment = { __typename: 'ComponentShopProductCollectionFilter', sortOrder: Types.ProductCollectionSortOrder, sortColumn?: Types.Enum_Componentshopproductcollectionfilter_Sortcolumn | null | undefined, productCount: number, taxonId?: number | null | undefined };

export type ErrorAttributesFragment = { __typename: 'Error', code: string, message?: string | null | undefined };

export type MetaDataAttributesFragment = { __typename: 'ComponentCommonMetaData', metaTitle: string, metaDescription?: string | null | undefined, metaKeywords?: string | null | undefined, metaImage?: { __typename: 'UploadFileEntityResponse', data?: { __typename: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename: 'UploadFile', name: string, alternativeText?: string | null | undefined, caption?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, hash: string, ext?: string | null | undefined, mime: string, size: number, url: string, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type DisplayControlAttributesFragment = { __typename: 'ComponentCommonDisplayControl', showOnDevices?: any | null | undefined };

export type VideoConfigAttributesFragment = { __typename: 'ComponentCommonVideoConfig', mode?: Types.Enum_Componentcommonvideoconfig_Mode | null | undefined };

export const CollectionFilterFragmentDoc = gql`
    fragment CollectionFilter on ComponentCommonCollectionFilter {
  sortColumn
  sortOrder
  pageSize
  filter
}
    `;
export const ProductCollectionFilterFragmentDoc = gql`
    fragment ProductCollectionFilter on ComponentShopProductCollectionFilter {
  sortOrder
  sortColumn
  productCount
  taxonId
}
    `;
export const ErrorAttributesFragmentDoc = gql`
    fragment ErrorAttributes on Error {
  code
  message
}
    `;
export const MetaDataAttributesFragmentDoc = gql`
    fragment MetaDataAttributes on ComponentCommonMetaData {
  metaTitle
  metaDescription
  metaImage {
    ...UploadFileResponse
  }
  metaKeywords
}
    ${UploadFileResponseFragmentDoc}`;
export const DisplayControlAttributesFragmentDoc = gql`
    fragment DisplayControlAttributes on ComponentCommonDisplayControl {
  showOnDevices
}
    `;
export const VideoConfigAttributesFragmentDoc = gql`
    fragment VideoConfigAttributes on ComponentCommonVideoConfig {
  mode
}
    `;